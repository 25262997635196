import { useQuery } from 'react-query';

const axios = require('axios').default;

export const getLineOfSight = async ({ queryKey }) => {
  // ? I'm destructuring the query key and assaigning variables to the indexes of the returned array.
  // ? Take note I'm not using the first item in the array and just assigned _ to it.
  // ? I'm defining the first one as _ since we don't care about the query tag being used.
  // ? queryKey[0] = _ & queryKey[1] = startPosition & queryKey[2] = endPosition

  try {
    const [_, startPosition, endPosition, endHeight, startHeight] = queryKey;
    const startLocation = `${startPosition.lat},${startPosition.lng}`;
    const endLocation = `${endPosition.lat},${endPosition.lng}`;

    const response = await axios.get(process.env.REACT_APP_LINE_OF_SIGHT_API_URL, {
      params: {
        key: process.env.REACT_APP_LINE_OF_SIGHT_API_KEY,
        start_location: startLocation,
        end_location: endLocation,
        start_height: startHeight,
        end_height: endHeight,
        include_elevation: true
      },
      headers: { 'Content-Type': 'application/json' },
      mode: 'no-cors'
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.data) throw error.response.data;
    else throw error;
  }
};

export const useLineOfSightQuery = (startPosition, endPosition, refetch) => {
  const { data, isLoading, isIdle, error } = useQuery(['useLineofSight', startPosition, endPosition]);
  return { data, isLoading, isIdle, error };
};
