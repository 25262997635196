/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Grid, makeStyles, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.only('xs')]: {
      paddingRight: '20px'
    },
    backgroundColor: '#D3D3D3'
  },
  textField: {
    margin: '5px',
    padding: '10px',
    [theme.breakpoints.up('xs')]: {
      width: '75%'
    },
    color: 'grey'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px'
  },
  btn: {
    background: 'red',
    color: 'white',
    '&:hover': {
      background: 'white',
      color: 'red'
    },
    width: '70%',
    marginTop: '30px',
    [theme.breakpoints.only('xs')]: {
      marginTop: '10px'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '20px'
    },
    marginBottom: '30px'
  },
  paper: {
    background: 'white',
    borderRadius: '15px',
    [theme.breakpoints.up('md')]: {
      height: '600px',
      width: '500px'
    },
    [theme.breakpoints.only('sm')]: {
      height: '600px',
      width: '400px'
    },
    [theme.breakpoints.only('xs')]: {
      height: '525px',
      width: '300px'
    },
    padding: '15px'
  },
  errorMsg: {
    color: 'red',
    fontSize: '20px'
  },
  loader: {
    color: '#91B3B3'
  }
}));

export function Login({ LoginFirebase }) {
  const [pass, setPass] = useState('');
  const [email, setEmail] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [isValid, setIsValid] = React.useState(true);

  const classes = useStyles();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const res = await LoginFirebase(email, pass);
    setIsValid(res);
  };

  const passwordToggle = () => {
    if (showPass) setShowPass(false);
    if (!showPass) setShowPass(true);
  };

  return (
    <div className={classes.root}>
      <Grid
        justifyContent="center"
        container
        direction="column"
        alignItems="center"
        style={{ minHeight: '100vh', padding: '5px' }}
      >
        <Paper className={classes.paper}>
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <img id="logo" src="assets/Logo.png" width="120" alt="28East Logo" />
            <font id="sign" size="+2">
              Sign In
            </font>
            <form className={classes.form} onSubmit={handleSubmit}>
              <div>
                <div>
                  <TextField
                    className={classes.textField}
                    required
                    id="outlined-required-email"
                    label="Email"
                    type="email"
                    variant="filled"
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                  <TextField
                    className={classes.textField}
                    required
                    id="outlined-required-password"
                    label="Password"
                    variant="filled"
                    type={showPass ? 'text' : 'password'}
                    onChange={(event) => {
                      setPass(event.target.value);
                    }}
                  />
                </div>

                <FormGroup style={{ paddingLeft: '20%' }}>
                  <FormControlLabel
                    control={<Checkbox color="secondary" onClick={passwordToggle} />}
                    label="Show Password"
                  />
                </FormGroup>

                <Button type="submit" className={classes.btn} variant="outlined" color="secondary">
                  Sign In
                </Button>

                <div className={classes.errorMsg}>{!isValid && 'Invalid email or password'}</div>
              </div>
            </form>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
}
