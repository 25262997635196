/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { AreaChart, XAxis, YAxis, Area, ReferenceDot, ResponsiveContainer, ReferenceLine, Tooltip } from 'recharts';

const YAxisFormat = (height) => `${height}m`;

function SVG(props) {
  const { cx, cy, label } = props;

  return (
    <svg
      x={cx - 15}
      y={cy - 30}
      width={30}
      height={30}
      viewBox="-2.5 1 16 16"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(-.34119 -1.8686)"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m5.5341 16.561 0.53162-0.64586c0.603-0.74316 1.1457-1.4501 1.6281-2.1209l0.39872-0.5661c1.6638-2.4147 2.4957-4.3302 2.4957-5.7464 0-3.0232-2.2628-5.474-5.0541-5.474s-5.0541 2.4508-5.0541 5.474c0 0.97923 0.39769 2.1971 1.1931 3.6536 0.35498 0.65006 0.78918 1.3477 1.3026 2.0928l0.39872 0.5661c0.62023 0.86244 1.3401 1.7847 2.1597 2.7668z"
        fill="#f00"
        opacity=".97"
        stroke="#fd0101"
        strokeWidth=".27767"
      />
      <ellipse cx="5.5117" cy="6.8482" rx="2.7375" ry="2.8611" fill="#fff" strokeWidth=".17925" />
      <text
        transform="scale(.96804 1.033)"
        x="4.0781531"
        y="8.3925476"
        fill="#000000"
        fontSize="4.9921px"
        opacity=".97"
        stroke="#060000"
        strokeWidth="0"
        fontFamily="sans-serif"
      >
        <tspan x="4.0781531" y="8.3925476" fill="#000000" stroke="#060000" strokeWidth="0">
          {label}
        </tspan>
      </text>
    </svg>
  );
}

function CustomTooltip({ active, payload, setTooltipActive, setChartMarkerPosition }) {
  useEffect(() => {
    setTooltipActive(true);
  }, [setTooltipActive, active]);

  useEffect(() => {
    if (payload[0]?.payload?.location) {
      setChartMarkerPosition({ lat: payload[0].payload.location?.lat, lng: payload[0].payload.location.lng });
    }
  }, [payload[0]?.payload?.location, setChartMarkerPosition]);

  return (
    <div className="text-black tooltip">
      <p className="tooltipLabel ">{`Height: ${payload[0]?.payload?.elevation}m`}</p>
      <p className="tooltipDesc">{`at location: ${payload[0]?.payload?.location?.lat}, ${payload[0]?.payload?.location?.lng}`}</p>
    </div>
  );
}

const styles = {
  fontFamily: 'raleway',
  height: '0.5px'
};

function getChartData(ChartData, distance) {

  const newData = ChartData.map((elevationData, index) => {
    const chartDataDistance = elevationData;
    const elevationDistance = (distance / ChartData.length) * index;
    if (distance <= 0.9) {
      chartDataDistance.Distance = elevationDistance.toFixed(2);
    } else if (distance >= 1 && distance < 9.5) {
      chartDataDistance.Distance = elevationDistance.toFixed(1);
    } else {
      chartDataDistance.Distance = Math.floor(parseFloat(elevationDistance).toFixed(2));
    }
    return chartDataDistance;

  });

  return newData;
}

function Chart({
  ChartData,
  onMouseClick,
  extraData,
  setChartMarkerPosition,
  setTooltipActive,
  Feasibile,
}) {

  const MarkerBIndex = extraData.distance > 99 ? ChartData[99].Distance : extraData.elementsLength

  return (
    <ResponsiveContainer>
      <AreaChart
        data={ChartData}
        margin={{ top: 25, right: 10, left: 14, bottom: 10 }}
        onMouseDown={onMouseClick}
        style={styles}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#808080" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#808080" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="Distance" domain={[0, extraData.distance]} unit="km" interval={10} />
        <YAxis
          domain={['dataMin-140', 'dataMax+140']}
          width={45}
          tickCount={20}
          tickFormatter={YAxisFormat}
          tickSize={5}
        />

        <Tooltip
          content={
            <CustomTooltip setChartMarkerPosition={setChartMarkerPosition} setTooltipActive={setTooltipActive} />
          }
        />

        {ChartData && (
          <>

            <ReferenceDot x={0} y={extraData.startHeight} shape={<SVG label="A" />} ifOverflow isFront />
            <ReferenceDot x={MarkerBIndex} y={extraData.endHeight} shape={<SVG label="B" />} ifOverflow isFront />
            <ReferenceLine
              stroke={Feasibile ? 'green' : 'red'}
              strokeWidth={2}
              ifOverflow
              segment={[
                { x: 0, y: extraData.startHeight },
                { x: MarkerBIndex, y: extraData.endHeight }
              ]}
            />
            <ReferenceLine
              stroke="black"
              strokeDasharray="3 3"
              ifOverflow
              segment={[
                { x: 0, y: extraData.startElevation },
                { x: 0, y: extraData.startHeight }
              ]}
            />
            <ReferenceLine
              stroke="black"
              strokeDasharray="3 3"
              ifOverflow
              segment={[
                { x: MarkerBIndex, y: extraData.endElevation },
                { x: MarkerBIndex, y: extraData.endHeight }
              ]}
            />
          </>
        )}

        <Area
          type="monotone"
          dataKey="elevation"
          stroke="#808080"
          fillOpacity={1}
          fill="url(#colorUv)"
        // isAnimationActive
        // onMouseOver={onMouseEnter}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

function ElevationChart({
  data,
  setPosition,
  setTooltipActive,
  isLoading,
  setChartMarkerPosition,
  toggleExpanded,
  expanded
}) {
  const [ChartData, setChartData] = useState([]);
  const [Feasibile, setFeasibile] = useState(false);
  const [extraData, setExtraData] = useState();

  const onMouseClick = (event) => {
    if (event?.activePayload[0]?.payload?.location) {
      setPosition({
        lat: event?.activePayload[0]?.payload?.location.lat,
        lng: event?.activePayload[0]?.payload?.location.lng
      });
    }
  };

  useEffect(() => {
    if (data?.elevations) {
      if (!data.start_location.height) {
        data.start_location.height = 0;
      }
      if (!data.end_location.height) {
        data.end_location.height = 0;
      }

      // configure elevations so that the heights of the start and end of elevation data represent the actual ground and not the height of the markers
      // data.elevations[0].elevation -= data.elevations[0].elevation - data.start_location.height;

      data.elevations[0].elevation -= data.start_location.height;
      data.elevations[data.elevations.length - 1].elevation =
        data.elevations[data.elevations.length - 1].elevation - data.end_location.height;

      const startHeight = data.start_location.elevation + data.start_location.height;
      const endHeight = data.end_location.elevation + data.end_location.height;

      const fixedDistance = (data.distance / 1000).toFixed(1)

      setChartData(getChartData(data.elevations, fixedDistance));
      setExtraData({
        distance: fixedDistance,
        startHeight,
        endHeight,
        startElevation: data.start_location.elevation,
        endElevation: data.end_location.elevation,
        elementsLength: data.elevations.length - 1
      });
    }

    if (data?.has_line_of_sight) {
      setFeasibile(data.has_line_of_sight);
    }

    if (!expanded) toggleExpanded(true);

    return () => {
      setChartData([]);
      setFeasibile(false);
    };
  }, [data.distance, data.elevations, data.end_location, data.has_line_of_sight, data.start_location]);

  return ChartData && extraData && !isLoading ? (
    <Chart
      ChartData={ChartData}
      onMouseClick={onMouseClick}
      extraData={extraData}
      setChartMarkerPosition={setChartMarkerPosition}
      setTooltipActive={setTooltipActive}
      Feasibile={Feasibile}
    />
  ) : (
    <div>No data </div>
  );
}

export default ElevationChart;
