import { onAuthStateChanged, signOut, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/firebase-config'

export const LoginFirebaseService = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password)
        return true
    } catch (error) {
        return (false);
    }
}

export const LogOutFirebaseService = async () => {
    try {
        await signOut(auth)
        return true
    } catch (error) {
        return false
    }
}
