/* eslint-disable react/prop-types */
import React from 'react'

function LosError({error}) {

  return (
    <div className="grow place-content-center px-10 font-raleway text-center text-red-700 bg-white mobile:text-sm md-mobile:text-base">
      <h2>Line of Sight invalid</h2>
      <p>{error.message.replace('150000m','150 000 meters')}.</p>
    </div>
  )
}

export default LosError