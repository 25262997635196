import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
// hooks
// import useSettings from '../hooks/useSettings';
// import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
// import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node
};

export default function ThemeConfig({ children }) {
  // const { themeMode } = useSettings();
  const isLight = true;

  const defaultTheme = {
    primary: {
      main: '#91B3B3'
    },
    secondary: {
      main: '#c62828'
    },
    info: {
      main: '#008FBF'
    },
    success: {
      main: '#50B52B'
    },
    warning: {
      main: '#F7B900'
    },
    error: {
      main: '#BF1E1E'
    }
  };

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? { ...palette.light, mode: 'light' } : { ...palette.dark, mode: 'dark' }
    }),
    [isLight]
  );

  const theme = createTheme(themeOptions, defaultTheme);
  // theme.components = componentsOverride(theme)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
