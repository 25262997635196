import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import './App.css';
import ThemeConfig from './theme';
import LineOfSightLayout from './layouts/LineOfSightLayout';
import { Login } from './components/Login/login';
import { LoginFirebaseService, LogOutFirebaseService } from './services/FirebaseAuth.service';

const queryClient = new QueryClient();

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const LoginFirebase = async (email, password) => {
    const response = await LoginFirebaseService(email, password)
    setIsLoggedIn(response);

    return (response)
  }

  const LogOutFirebase = () => {
    LogOutFirebaseService()
    setIsLoggedIn(false)
  }

  

  return (
    <ThemeConfig>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          {!isLoggedIn ?
            <Login
              LoginFirebase={LoginFirebase}
            /> : <LineOfSightLayout logOut={LogOutFirebase} />
          }
        </div>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ThemeConfig>
  );
}

export default App;
