/* eslint-disable react/prop-types */
import { Polyline } from '@react-google-maps/api';
import React from 'react';

function ElevationPolyline({ feasible, paths, isLoading }) {
  const options = {
    // eslint-disable-next-line no-nested-ternary
    strokeColor: isLoading ? 'orange' : feasible ? 'green' : 'red',
    zIndex: 0
  };

  return <Polyline options={options} path={paths} />;
}

export default ElevationPolyline;
