import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCt-xlAr2SXF1LnahWcN2vohrRO58Kp7SM",
  authDomain: "line-of-sight-api.firebaseapp.com",
  projectId: "line-of-sight-api",
  storageBucket: "line-of-sight-api.appspot.com",
  messagingSenderId: "1030890241013",
  appId: "1:1030890241013:web:eeb13e76aec7e2d7a7febc",
  measurementId: "G-D4G9W7PH4Y"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
