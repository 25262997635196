/* eslint-disable react/prop-types */
import { Container, Grid, Toolbar, Typography, Accordion, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import ElevationChart from '../components/ElevationChart/ElevationChart';
import GoogleMapComponent from '../components/GoogleMap/GoogleMapComponent';
import LocationDetail from '../components/LocationDetail';
import { getLineOfSight } from '../services/LineOfSight.service';
import styles from './LineOfSightLayout.module.css';

import useDebounce from '../hooks/useDebounce';

import HowTo from '../components/HowTo';
import useToggle from '../hooks/useToggle';
import LosError from '../components/LosError';

const useStyles = makeStyles(() => ({
  AccordionRoot: {
    width: 'calc(100% - 20px)',
    position: 'absolute',
    bottom: 25,
    marginLeft: '10px',
    marginRight: '10px'
  },
  AccordionExpanded: {
    width: 'calc(100% - 20px)',
    backgroundColor: '#bdbdbd',
    marginLeft: '10px',
    marginRight: '10px'
  }
}));

function LineOfSightLayout({ logOut }) {
  const classes = useStyles();
  // ? Interaction logic
  // ! This is specific logic related to the UI components.
  // ? Passing data around here is normal and handling events or data transitions
  const [startPosition, setStartPosition] = useState();
  const [endPosition, setEndPosition] = useState();
  const [zoomPosition, setZoomPosition] = useState();

  const [expanded, toggleExpanded] = useToggle();

  const [isMobile, setIsMobile] = useState(!(window.innerWidth > 1200));
  const [startHeight, setStartHeight] = useState(10);
  const [endHeight, setEndHeight] = useState(10);
  const debouncedStartHeight = useDebounce(startHeight, 500);
  const debouncedEndHeight = useDebounce(endHeight, 500);

  const [ChartMarkerPosition, setChartMarkerPosition] = useState();

  const [tooltipActive, setTooltipActive] = useState(false);

  const { refetch, isLoading, data, isError, error } = useQuery(
    ['lineOfSight', startPosition, endPosition, debouncedEndHeight, debouncedStartHeight],
    getLineOfSight,
    { retry: false, enabled: !!startPosition && !!endPosition, refetchOnWindowFocus: false }
  );

  function handleResize() {
    if (window.innerWidth < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  const onMouseLeave = () => {
    setTooltipActive(false);
    setChartMarkerPosition();
  };

  window.addEventListener('resize', handleResize);

  return (
    <div className={styles.screen}>
      <AppBar className={styles.appBar} color="transparent" elevation={0} position="static">
        <Toolbar className="justify-between">
          <div className="">
            <a href="https://www.28east.co.za/" target="_blank" rel="noreferrer">
              <img
                src="https://static.wixstatic.com/media/90a550_872175211e47425db69679dce7e8f177~mv2.png/v1/fill/w_80,h_60,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/28East-alpha_edited_edited.png"
                alt="28East-alpha_edited_edited.png"
                className={styles.logo}
              />
            </a>
          </div>

          <div>
            <Typography className="font-raleway text-lg text-black md-mobile:text-2xl">
              Line of Sight Feasibility
            </Typography>
          </div>
          <div>
            <Button
              className="text-sm text-white hover:text-red-600 bg-red-600 hover:bg-white"
              onClick={logOut}
              variant="outlined"
              color="secondary"
            >
              Log Out
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <Container className="mt-3" disableGutters maxWidth={false}>
        <Grid>
          <Grid item className={styles.mapContainer}>
            <GoogleMapComponent
              startPosition={startPosition}
              endPosition={endPosition}
              setStartPosition={setStartPosition}
              setEndPosition={setEndPosition}
              isLoading={isLoading}
              data={data}
              ChartMarkerPosition={ChartMarkerPosition}
              tooltipActive={tooltipActive}
              startHeight={startHeight}
              endHeight={endHeight}
              setStartHeight={setStartHeight}
              setEndHeight={setEndHeight}
              zoomPosition={zoomPosition}
              toggle={expanded}
            />
          </Grid>
          <Accordion
            classes={{
              root: classes.AccordionRoot,
              expanded: classes.AccordionExpanded
            }}
            expanded={expanded}
            className="grow rounded-2xl shadow-md"
          // disableGutters
          >
            <AccordionSummary className="px-10" onClick={toggleExpanded} expandIcon={<ExpandMore />}>
              {!data && (
                <Typography className="grow font-raleway text-lg text-center text-black md-mobile:text-2xl">
                  Line of Sight graph
                </Typography>
              )}
              {data && data?.has_line_of_sight ? (
                <Typography className="grow font-raleway text-lg text-center text-green-700 md-mobile:text-2xl">
                  Line of Sight Feasible
                </Typography>
              ) : data && !data?.has_line_of_sight && (
                <Typography className="grow font-raleway text-lg text-center text-red-700 md-mobile:text-2xl">
                  Line of Sight Obstructed
                </Typography>
              )}

            </AccordionSummary>
            <Grid
              container
              wrap="nowrap"
              alignItems="stretch"
              justifyContent="space-between"
              className={styles.infoDisplay}
            >
              {!isMobile && (
                <Grid item xs={2} className="overflow-y-auto bg-white">
                  <LocationDetail
                    location={startPosition}
                    data={data}
                    isLoading={isLoading}
                    Position="A"
                    isError={isError}
                  />
                </Grid>
              )}
              <Grid
                container
                item
                xs={isMobile ? 12 : 8}
                className={
                  !data?.elevations
                    ? 'overflow-auto px-5 bg-white border-x border-black pt-5 pb-5'
                    : 'overflow-y-hidden px-5 bg-white border-x border-black pt-5 pb-5 w-screen '
                }
              >
                {isError && !isLoading && <LosError className="text-center bg-white" error={error} />}
                {!data?.elevations && !error && <HowTo className="text-center bg-white" />}
                {!isError && data?.elevations && (
                  <div style={{ width: '99%', paddingBottom: '10px' }} onMouseLeave={onMouseLeave}>
                    <ElevationChart
                      startPosition={startPosition}
                      endPosition={endPosition} de
                      data={data}
                      isLoading={isLoading}
                      setChartMarkerPosition={setChartMarkerPosition}
                      setTooltipActive={setTooltipActive}
                      setPosition={setZoomPosition}
                      toggleExpanded={toggleExpanded}
                      expanded={expanded}
                    />
                    <div>
                      Distance
                      {`: ${(data.distance / 1000).toFixed(1)}km`}
                    </div>
                  </div>
                )}
              </Grid>
              {!isMobile && (
                <Grid item xs={2} className="overflow-y-auto bg-white">
                  <LocationDetail
                    location={endPosition}
                    data={data}
                    isLoading={isLoading}
                    Position="B"
                    isError={isError}
                  />
                </Grid>
              )}
            </Grid>
          </Accordion>
        </Grid>
      </Container>
    </div>
  );
}

export default LineOfSightLayout;
