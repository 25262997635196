/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Marker, StreetViewPanorama } from '@react-google-maps/api';
import map from '../../theme/map';
import Autocomplete from '../Autocomplete';
import ElevationPolyline from '../ElevationPolyline';

const center = {
  lat: -28.879171155528134,
  lng: 24.75089993730035
};

// const options = {
//   mapTypeControlOptions: {
//     position: window.google.maps.ControlPosition.LEFT_BOTTOM,
//   },
// }

const libraries = ['places'];

function GoogleMapComponent({
  data,
  startPosition,
  endPosition,
  setStartPosition,
  setEndPosition,
  isLoading,
  ChartMarkerPosition,
  tooltipActive,
  startHeight,
  endHeight,
  setEndHeight,
  setStartHeight,
  zoomPosition,
  toggle
}) {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries
  });

  const [paths, setPaths] = useState([]);
  const [lastMarker, setLastMarker] = useState();
  const [feasible, setFeasible] = useState(false);
  const [streetViewStatus, setStreetViewStatus] = useState(false);
  const MapRef = useRef();
  const StreetViewRef = useRef();
  const [isMobile, setIsMobile] = useState()

  const handleMapLoad = useCallback((mapInstance) => {
    MapRef.current = mapInstance;
  }, []);

  const onVisibleChanged = () => {
    setStreetViewStatus(StreetViewRef.current.getVisible());
  };

  function handleResize() {
    if (window.innerWidth < 660) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }
  window.addEventListener('resize', handleResize);

  const handleStreetViewLoad = useCallback((streetViewInstance) => {
    StreetViewRef.current = streetViewInstance;
  }, []);

  useEffect(() => {
    if (startPosition && endPosition) {
      setPaths([startPosition, endPosition]);

      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(startPosition);
      bounds.extend(endPosition);

      MapRef.current.fitBounds(bounds);
      MapRef.current.setZoom(MapRef.current.getZoom() - 1);
    }
  }, [startPosition, endPosition]);

  useEffect(() => {
    if (zoomPosition) {
      // const bounds = new window.google.maps.LatLngBounds();
      // bounds.extend(zoomPosition);

      MapRef.current.panTo(zoomPosition);
      MapRef.current.setZoom(MapRef.current.getZoom() + 2);
      console.log('MapRef ||', MapRef.current.getStreetView());
    }
  }, [zoomPosition]);

  const handleClick = (MapMouseEvent) => {
    // console.log("Mouse click: ",MapMouseEvent.latLng)
    if (lastMarker === 'B' || !lastMarker) {
      setStartPosition({ lat: MapMouseEvent.latLng.lat(), lng: MapMouseEvent.latLng.lng() });
      setLastMarker('A');
    } else {
      setEndPosition({ lat: MapMouseEvent.latLng.lat(), lng: MapMouseEvent.latLng.lng() });
      setLastMarker('B');
    }
  };

  useEffect(() => {
    if (data?.has_line_of_sight) {
      setFeasible(data?.has_line_of_sight);
    }

    return () => {
      setFeasible(false);
    };
  }, [data?.has_line_of_sight]);

  const handleStartDragMarker = () => {
    setPaths([]);
  };

  const handleStartDragMarkerEnd = (MapMouseEvent) => {
    setStartPosition({ lat: MapMouseEvent.latLng.lat(), lng: MapMouseEvent.latLng.lng() });
  };

  const handleEndDragMarkerEnd = (MapMouseEvent) => {
    setEndPosition({ lat: MapMouseEvent.latLng.lat(), lng: MapMouseEvent.latLng.lng() });
  };

  const mapOptions = {
    mapTypeControl: true,
    fullscreenControl: false,
    mapTypeControlOptions: {
      position: 3.0
    },
    zoomControlOptions: {
      position: 8.0
    },
    streetViewControlOptions: {
      position: 8.0
    },
    mapId: 'bcb5c2de2de3faff',
    rotateControl: false
  };

  if (isMobile) {
    mapOptions.mapTypeControl = false;
    mapOptions.zoomControl = false;
    mapOptions.streetViewControlOptions.position = 3.0
  } else {
    mapOptions.mapTypeControl = true;
    mapOptions.zoomControl = true;
  }
  if (toggle) {
    mapOptions.zoomControlOptions.position = 3.0
    mapOptions.streetViewControlOptions.position = 3.0
  } else {
    mapOptions.zoomControlOptions.position = 8.0
  }

  const renderMap = () => (
    <GoogleMap
      options={mapOptions}
      mapContainerStyle={map}
      center={center}
      zoom={6}
      onLoad={handleMapLoad}
      onClick={handleClick}
    >
      <StreetViewPanorama onVisibleChanged={onVisibleChanged} onLoad={handleStreetViewLoad} />
      {startPosition && (
        <Marker
          position={startPosition}
          draggable
          icon={{
            url: 'assets/locationAMarker.svg'
          }}
          onDrag={handleStartDragMarker}
          onDragEnd={handleStartDragMarkerEnd}
        />
      )}

      {endPosition && (
        <Marker
          position={endPosition}
          draggable
          icon={{
            url: 'assets/locationBMarker.svg'
          }}
          onDrag={handleStartDragMarker}
          onDragEnd={handleEndDragMarkerEnd}
        />
      )}

      {paths && <ElevationPolyline feasible={feasible} paths={paths} isLoading={isLoading} />}

      {tooltipActive && ChartMarkerPosition && (
        <Marker
          position={ChartMarkerPosition}
          icon={{
            // eslint-disable-next-line no-undef
            path: google.maps.SymbolPath.CIRCLE,
            scale: 8.5,
            fillColor: '#8884D8',
            fillOpacity: 1,
            strokeWeight: 0.4
          }}
          options={{
            zIndex: 1
          }}
        />
      )}

      {!streetViewStatus && (
        <div>
          <Autocomplete
            heightValue={startHeight}
            setHeightValue={setStartHeight}
            setPostion={setStartPosition}
            currentLocation={startPosition}
            setPaths={setPaths}
            Paths={paths}
            index={0}
            label="Location A"
          // isStreetViewMode={isStreetView}
          />

          <Autocomplete
            heightValue={endHeight}
            setHeightValue={setEndHeight}
            setPostion={setEndPosition}
            currentLocation={endPosition}
            setPaths={setPaths}
            Paths={paths}
            index={1}
            label="Location B"
          // isStreetViewMode={isStreetView}
          />
        </div>
      )}
    </GoogleMap>
  );

  if (loadError) {
    return <div>Map not loaded</div>;
  }

  return isLoaded ? renderMap() : <div>Loading</div>;
}

export default GoogleMapComponent;
