import React from 'react';

function howTo() {
  return (
    <div className="grow place-content-center px-10 font-raleway text-left bg-white mobile:text-sm md-mobile:text-base">
      <h2>How to use the Line of Sight tool:</h2>
      <p>This application can be used to visualise the topography between two points and determine the line of sight.</p>
      <p>
        In order to do this, two markers must be placed on the map. 
        Markers can be placed by entering an address, latitude and longitude co-ordinates or plus codes into the location fields, or by left clicking on the map.
      </p>
      <br />
      <p className="mt-0 text-xs">
      Please note that the tool only supports a maximum distance of 150km between the two points and a maximum height of 100 meter.
      </p>
    </div>
  );
}

export default howTo;
