/* eslint-disable react/prop-types */
import { Skeleton, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Geocode from 'react-geocode';

function LocationPanel({ address, start, end, Position }) {

  return (
    <div>
      <h3 className="p-1 mt-1 font-raleway text-xl">{`Location ${Position}`}</h3>
      <Typography className="px-5 font-raleway text-lg text-left">
        <b>Address</b>
        {`: ${address} `}
      </Typography>
      <Typography className="px-5 font-raleway text-lg text-left">
        <b>Height</b>
        {`: ${Position === 'A' ? start.height : end.height}m`}
      </Typography>
      <Typography className="px-5 font-raleway text-lg text-left">
        <b>Elevation</b>
        {`: ${Position === 'A' ? start.elevation : end.elevation}m`}
      </Typography>
    </div>
  );
}

function LocationDetail({ location, isLoading, Position, data, isError }) {
  const [address, setAddress] = useState(null);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  useEffect(() => {
    if (data && Position && location.lat) {
      setStart(data.start_location);
      setEnd(data.end_location);
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
      Geocode.setRegion('za');

      Geocode.fromLatLng(location?.lat, location?.lng).then(
        (response) => {
          setAddress(response.results[0].formatted_address);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [start, end, data, Position, location]);

  return start && end && !isError && location ? (
    <LocationPanel address={address} start={start} end={end} Position={Position} />
  ) : (
    <div>
      {/* <h3 className="p-1 mt-1 font-raleway text-xl text-center">Location Details</h3> */}
      {isLoading && (
        <div className="px-5 pt-5">
          <Skeleton className="pb-1 mb-2" animation="wave" />
          <Skeleton className="pb-1 mb-2" animation="wave" />
          <Skeleton className="pb-1 mb-2" animation="wave" />
        </div>
      )}
    </div>
  );
}

export default LocationDetail;
